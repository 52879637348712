<template>
	<div>
		<div
			class="ac-detail-mobile js-copy js-content pd_body"
			@scroll="handleScroll"
		>
			<topHeader></topHeader>

			<div class="ac_detail_bg_top">
				<div class="ac_detail_ticket" @mouseover="hideInfo()">
					<div class="ac_detail_ticket_left">
						<img :src="item.pic" />
						<div class="ac_calendar_start_hour">
							{{ formatTime(item.start_time) }}
						</div>
						<div
							class="ac_calendar_line"
							:class="{ ac_calendar_line_noStart: item.startStatus == 0 }"
							v-if="item.startStatus == 0"
						>
							<div></div>
							活动尚未开始
						</div>
						<div
							class="ac_calendar_line"
							:class="{ ac_calendar_line_end: item.startStatus == 2 }"
							v-if="item.startStatus == 2"
						>
							<div></div>
							活动已结束
						</div>
						<div
							class="ac_calendar_line"
							:class="{
								ac_calendar_line_progress: item.startStatus == 1,
							}"
							v-if="item.startStatus == 1"
						>
							<div></div>
							活动正在进行中
						</div>
					</div>
					<div class="ac_detail_ticket_right">
						<div class="ac_detail_ticket_top">
							<div class="ac_detail_content_online">
								{{ item.type == 5 ? "线上活动" : "线下活动" }}
							</div>
							<div
								class="ac_detail_ticket_sideevent"
								v-if="item.mainevent != null && item.mainevent != ''"
							>
								{{ item.mainevent }}
							</div>
						</div>

						<div class="ac_detail_ticket_title">{{ item.title }}</div>
						<div class="ac_detail_ticket_date">
							<div class="ac_detail_ticket_info">
								<div class="ac_detail_ticket_info_item">
									<img
										src="https://res.metaera.hk/resources/assets/images/active/15_1.png"
									/>
									<span>{{ item.address }}</span>
								</div>
								<div class="ac_detail_ticket_info_item">
									<img
										src="https://res.metaera.hk/resources/assets/images/active/16.png"
									/>
									<span class="ac_detail_ticket_info_item_time">
										{{ formatDate(item.start_time) }} 至
										{{ formatDate(item.end_time) }} {{ item.timeZone }}
									</span>
									<div class="ac_detail_ticket_info_item_time_div">
										<div>
											<span>开始：</span>
											<span
												>{{ formatDate(item.start_time) }}
												{{ item.timeZone }}</span
											>
										</div>
										<div>
											<span>结束：</span>
											<span
												>{{ formatDate(item.end_time) }}
												{{ item.timeZone }}</span
											>
										</div>
									</div>
								</div>

								<div
									class="ac_detail_ticket_info_item ac_detail_ticket_info_item1"
									v-if="
										item.specialCode != undefined && item.specialCode.length > 0
									"
									@click="copyText(item.specialCode)"
								>
									<img
										src="https://res.metaera.hk/resources/assets/images/active/61.png"
									/>

									<div class="ac_detail_ticket_info_item_bg">
										<span>MetaEra专属邀请码：{{ item.specialCode }}</span>
										<img
											src="https://res.metaera.hk/resources/assets/images/active/62.png"
										/>
									</div>
								</div>
								<div class="ac_detail_ticket_people">
									<div
										class="ac_detail_ticket_info_item"
										style="margin-top: 10px"
									>
										<img
											src="https://res.metaera.hk/resources/assets/images/home/ac_people.png"
										/>
										<span style=""></span>
									</div>
									<div class="ac_detail_ticket_people_item">
										<div class="ac_detail_ticket_people_title">
											<div class="ac_detail_ticket_people_title_1">主办方</div>
											<div
												class="ac_detail_ticket_people_title_2"
												@click="hostMore()"
											>
												更多
												<img
													v-if="hostsBool"
													src="https://res.metaera.hk/resources/assets/images/active/arrowb.png"
												/>
												<img
													v-if="!hostsBool"
													src="https://res.metaera.hk/resources/assets/images/active/arrowt.png"
												/>
											</div>
										</div>
										<div
											class="ac_detail_ticket_people_list"
											:class="{ ac_detail_ticket_people_list_hide: hostsBool }"
											ref="peopleList_host"
										>
											<div
												v-for="(item, index) in hostsArray"
												:key="index"
												class="host-item-wrapper"
												ref="listItem_host"
											>
												<div
													class="ac_detail_ticket_people_list_item"
													@mouseenter="showInfo(index)"
													@click="toggleInfo(index)"
												>
													<img :src="item.pic" />
												</div>
												<div
													v-if="hoverHostIndex == index"
													class="ac_detail_ticket_people_list_item_layer"
													@mouseleave="hideInfo()"
													:style="layerStyle"
												>
													<div
														class="ac_detail_ticket_people_list_item_layer_img"
													>
														<img :src="item.pic" />
													</div>
													<div
														class="ac_detail_ticket_people_list_item_layer_name"
													>
														{{ item.name }}
													</div>
													<div
														class="ac_detail_ticket_people_list_item_layer_content"
													>
														{{ item.content }}
													</div>
													<div
														class="ac_detail_ticket_people_list_item_layer_url"
													>
														<div
															v-if="item.insLink.length > 0"
															@click.stop="showUrl(item.insLink)"
														>
															<img
																src="https://res.metaera.hk/resources/assets/images/active/35.png"
															/>
														</div>
														<div
															v-if="item.twLink.length > 0"
															@click.stop="showUrl(item.twLink)"
														>
															<img
																src="https://res.metaera.hk/resources/assets/images/active/36_1.png"
															/>
														</div>
														<div
															v-if="item.ytLink.length > 0"
															@click.stop="showUrl(item.ytLink)"
														>
															<img
																src="https://res.metaera.hk/resources/assets/images/active/37.png"
															/>
														</div>
														<div
															v-if="item.tgLink.length > 0"
															@click.stop="showUrl(item.tgLink)"
														>
															<img
																src="https://res.metaera.hk/resources/assets/images/active/56.png"
															/>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="ac_detail_ticket_people_item">
										<div class="ac_detail_ticket_people_title">
											<div class="ac_detail_ticket_people_title_1">协办方</div>
											<div
												class="ac_detail_ticket_people_title_2"
												@click="coHostMore()"
												>更多
												<img
													v-if="coHostsBool"
													src="https://res.metaera.hk/resources/assets/images/active/arrowb.png"
												/>
												<img
													v-if="!coHostsBool"
													src="https://res.metaera.hk/resources/assets/images/active/arrowt.png"
												/>
											</div>
										</div>
										<div
											class="ac_detail_ticket_people_list"
											:class="{
												ac_detail_ticket_people_list_hide: coHostsBool,
											}"
											ref="peopleList"
										>
											<div
												v-for="(item, index) in coHostsArray"
												:key="index"
												class="host-item-wrapper"
												ref="listItem"
											>
												<div
													class="ac_detail_ticket_people_list_item"
													@mouseenter="coShowInfo(index)"
													@click="coToggleInfo(index)"
												>
													<img :src="item.pic" />
												</div>

												<div
													v-if="hoverCoHostIndex == index"
													class="ac_detail_ticket_people_list_item_layer"
													@mouseleave="hideInfo()"
													:style="layerStyle"
												>
													<div
														class="ac_detail_ticket_people_list_item_layer_img"
													>
														<img :src="item.pic" />
													</div>
													<div
														class="ac_detail_ticket_people_list_item_layer_name"
													>
														{{ item.name }}
													</div>
													<div
														class="ac_detail_ticket_people_list_item_layer_content"
													>
														{{ item.content }}
													</div>
													<div
														class="ac_detail_ticket_people_list_item_layer_url"
													>
														<div
															v-if="item.insLink.length > 0"
															@click.stop="showUrl(item.insLink)"
														>
															<img
																src="https://res.metaera.hk/resources/assets/images/active/35.png"
															/>
														</div>
														<div
															v-if="item.twLink.length > 0"
															@click.stop="showUrl(item.twLink)"
														>
															<img
																src="https://res.metaera.hk/resources/assets/images/active/36_1.png"
															/>
														</div>
														<div
															v-if="item.ytLink.length > 0"
															@click.stop="showUrl(item.ytLink)"
														>
															<img
																src="https://res.metaera.hk/resources/assets/images/active/37.png"
															/>
														</div>
														<div
															v-if="item.tgLink.length > 0"
															@click.stop="showUrl(item.tgLink)"
														>
															<img
																src="https://res.metaera.hk/resources/assets/images/active/56.png"
															/>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="ac_detail_ticket_info_item_btn">
									<div
										class="ac_detail_ticket_info_item_share ac_detail_ticket_info_item_share_people pointer"
									>
										<div
											class="ac_detail_ticket_info_item_share_img ac_detail_ticket_info_item_share_img_people"
										></div>
										<span
											class="ac_detail_ticket_info_item_share_text ac_detail_ticket_info_item_share_text_people pointer"
											@click="showUrl(item.url)"
										>
											参加活动
										</span>
									</div>
									<div class="ac_detail_ticket_info_item_share pointer">
										<div class="ac_detail_ticket_info_item_share_img"></div>
										<span
											class="ac_detail_ticket_info_item_share_text pointer"
											@click="share(item.id)"
										>
											分享活动
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div
				class="ac_detail_specialCode"
				v-if="item.specialCode != undefined && item.specialCode.length > 0"
				@click="copyText(item.specialCode)"
			>
				<div class="ac_detail_specialCode_title">
					<span>MetaEra专属邀请码</span>
				</div>
				<div class="ac_detail_specialCode_code">
					<span>{{ item.specialCode }}</span>
				</div>
				<div class="ac_detail_specialCode_btn"><span>复制</span></div>
			</div>

			<div class="ac_detail_overview" @mouseover="hideInfo()">
				<div class="ac_detail_overview_header">
					<div class="ac_detail_overview_title_new">Event Overview</div>
					<div
						class="ac_detail_overview_title_expand"
						@click="toggleContent()"
						v-if="item.sideeventArray && item.sideeventArray.length > 0"
					>
						<img
							src="https://res.metaera.hk/resources/assets/images/home/arrow_black_down.png"
							v-if="this.isCollapsed"
						/>
						<img
							src="https://res.metaera.hk/resources/assets/images/home/arrow_black_top.png"
							v-else
						/>
					</div>
				</div>
				<div
					:class="[
						'ac_detail_overview_content ac_detail_overview_content_mobile',
						isCollapsed && item.sideeventArray && item.sideeventArray.length > 0
							? 'collapsed'
							: 'expanded',
					]"
				>
					<p class="ql-editor" v-html="item.content"></p>
				</div>
			</div>

			<div
				class="ac_detail_filter"
				v-if="item.sideeventArray && item.sideeventArray.length > 0"
			>
				<div class="ac_detail_overview_title">Side Events</div>
				<!-- 活动类型的筛选条件，只有独家需要显示 -->
				<div class="ac_detail_filter_content">
					<div class="ac_calendar_filter_header">
						<div>活动筛选</div>
						<div @click="clearFilter()" style="font-size: 14px">清除筛选</div>
					</div>
					<div class="ac_calendar_filter_time" @click="calendarShow = true">
						<span>日期</span>
						<span>{{ this.startTime.slice(0, 10) }}</span>
						<span>-</span>
						<span>{{ this.endTime.slice(0, 10) }}</span>
						<img
							src="https://res.metaera.hk/resources/assets/images/event/右 点击展开 三角形@2x (1).png"
							alt=""
						/>
					</div>
					<van-calendar
						v-model="calendarShow"
						type="range"
						:min-date="minDate"
						:max-date="maxDate"
						:show-confirm="false"
						:allow-same-day="true"
						color="#333"
						@confirm="onConfirm"
					/>

					<div class="ac_category_list_wrapper_detail">
						<div class="ac_category_list">
							<div
								v-for="(item, index) in maineventArray"
								:key="index"
								class="ac_category_list_item"
								@click="categoryClick(item)"
								:class="{
									ac_category_list_item_sel: item == categoryValue,
								}"
							>
								{{ item }}
							</div>
						</div>
					</div>
				</div>
				<div
					v-for="(side, index) in this.item.sideeventArray"
					:key="index"
					class="ac_detail_side_content"
				>
					<template v-if="judgeSideEventShow(side)">
						<template v-if="side.pic">
							<div
								class="ac_detail_side_content_item"
								@click="showSideDetail(side.id)"
							>
								<div class="ac_detail_side_content_item_pic">
									<img :src="side.pic" />
								</div>
								<div class="ac_detail_side_content_item_info">
									<div class="ac_detail_ticket_title">{{ side.title }}</div>
									<div class="ac_detail_ticket_date">
										<div class="ac_detail_ticket_info">
											<div class="ac_detail_ticket_info_item">
												<img
													src="https://res.metaera.hk/resources/assets/images/active/15_1.png"
												/>
												<span>{{ side.address }}</span>
											</div>
											<div class="ac_detail_ticket_info_item">
												<img
													src="https://res.metaera.hk/resources/assets/images/active/16.png"
												/>
												<span class="ac_detail_ticket_info_item_time">
													{{ formatDate(side.start_time) }} 至
													{{ formatDate(side.end_time) }} {{ side.timeZone }}
												</span>
												<div class="ac_detail_ticket_info_item_time_div">
													<div>
														<span>开始：</span>
														<span
															>{{ formatDate(side.start_time) }}
															{{ side.timeZone }}</span
														>
													</div>
													<div>
														<span>结束：</span>
														<span
															>{{ formatDate(side.end_time) }}
															{{ side.timeZone }}</span
														>
													</div>
												</div>
											</div>
											<div
												class="ac_detail_ticket_info_item"
												v-if="side.url != undefined && side.url.length > 0"
											>
												<img
													src="https://res.metaera.hk/resources/assets/images/active/17.png"
												/>
												<span
													class="ac_detail_ticket_info_item_url pointer"
													style="color: #0256ff"
													@click="showUrl(side.url)"
												>
													{{ side.url }}
												</span>
											</div>
											<div
												class="ac_detail_ticket_info_item ac_detail_ticket_info_item1"
												v-if="
													side.specialCode != undefined &&
													side.specialCode.length > 0
												"
												@click="copyText(side.specialCode)"
											>
												<img
													src="https://res.metaera.hk/resources/assets/images/active/61.png"
												/>

												<div
													class="ac_detail_ticket_info_item_bg ac_detail_ticket_info_item_bg_copy"
												>
													<span>MetaEra专属邀请码：{{ side.specialCode }}</span>
													<img
														src="https://res.metaera.hk/resources/assets/images/active/62.png"
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</template>
						<template v-else>
							<perCooperate :side="side"></perCooperate>
						</template>
					</template>
				</div>
				<div v-if="!isHaveItem" class="ac_calendar_aclist_empty">
					筛选后无任何活动，请重新筛选
				</div>
			</div>

			<bottom></bottom>
		</div>
	</div>
</template>

<script>
import { getToken, getUserMobile } from "@/utils/auth";
import store from "@/store";
import topHeader from "../../components/pc/topHeader2.vue";
import bottom from "../../components/pc/bottom.vue";
import hostMore from "./ac_host_more.vue";
import { getShareSignaTure } from "@/api/user";
import clipboard from "clipboard";
import { acitvityDetail, acitvityOrder } from "@/api/home";
import { getUserId } from "@/utils/auth";
import acSuccess from "./ac_success.vue";
import acDetailPc from "./ac_detail_pc.vue";
import { loadStripe } from "@stripe/stripe-js";
import perCooperate from "./per_cooperate.vue";
import Vue from "vue";
import { Calendar } from "vant";
import "vant/lib/index.css"; // 引入Vant的样式文件
import wx from "weixin-js-sdk";

Vue.use(Calendar);
export default {
	name: "",
	components: {
		topHeader,
		bottom,
		hostMore,
		acSuccess,
		acDetailPc,
		perCooperate,
	},
	data() {
		return {
			layerStyle: {}, // 用于动态设置浮层的样式
			hostsBool: true,
			coHostsBool: false,
			token: getToken(),
			item: {},
			showHost: false,
			tmpArray: [],
			tipTitle: "",
			loading: false,
			id: "",
			hostsArray: [],
			coHostsArray: [],
			realHostsArray: [],
			realCoHostsArray: [],
			hoverHostIndex: 9999,
			hoverCoHostIndex: 9999,
			windowWidth: 1280,
			showSuccess: false,
			tipTitle: "活动参与成功",
			tipMessage: "",
			ticket_sel_index: 0,
			ticketArray: [],
			count: 1,
			code: "",
			onCheck: 0,
			isCollapsed: true,
			minDate: new Date("2020/01/01"),
			maxDate: new Date("2030/01/01"), // 最大日期（3个月后）
			state: 0,
			stateArray: ["All", "Not started", "In progress", "Over"],
			type: 0,
			calendarShow: false,
			maineventArray: [
				"全部",
				"Afterparty",
				"Meetup",
				"Summit",
				"Networking",
				"Dinner / Gala",
				"Hackathon",
				"Workshop",
				"Other",
			],
			sharetitle: "MetaEra",
			sharedescs:
				"MetaEra是Web3行业的品牌与增长专家，为客户提供创意型解决方案。MetaEra在全球各地区利用全方位优势资源，为您的品牌管理和业务增长提供定制化服务。",
			wxsharelink: location.href,
			shareimg: "https://res.metaera.hk/resources/logo_share.png",
			categoryValue: "全部",
			startTime: "开始日期",
			endTime: "结束日期",
			isHaveItem: true,
		};
	},
	mounted() {
		this.token = getToken();
	},
	created() {
		this.token = getToken();
		this.id = this.$route.params.id;
		this.getAcitvityDetail();
	},
	computed: {
		isMobileDevice() {
			return this.$store.state.app.isMobileDevice;
		},
	},
	methods: {
		clearFilter() {
			this.categoryValue = "全部";
			this.startTime = "开始日期";
			this.endTime = "结束日期";
		},
		formatDate(date) {
			return `${date.getMonth() + 1}/${date.getDate()}`;
		},
		onConfirm(date) {
			const [start, end] = date;
			this.calendarShow = false;
			this.startTime = this.formatDate(start);
			this.endTime = this.formatDate(end);
			this.isHaveItem = false;
		},
		categoryClick(item) {
			this.categoryValue = item;
			this.isHaveItem = false;
		},
		judgeSideEventShow(item) {
			let isShow = false;
			const start_time = new Date(item.start_time);
			const end_time = new Date(item.end_time);
			const startTime = new Date(this.startTime);
			startTime.setHours(0, 0, 0, 0); // 设置为当天的最后一秒（23:59:59.999）

			const endTime = new Date(this.endTime);
			endTime.setHours(23, 59, 59, 999); // 设置为当天的最后一秒（23:59:59.999）

			if (
				((((start_time.getTime() <= endTime.getTime() &&
					start_time.getTime() >= startTime.getTime()) ||
					(end_time.getTime() <= endTime.getTime() &&
						end_time.getTime() >= startTime.getTime())) &&
					this.startTime != "开始日期") ||
					this.startTime == "开始日期") &&
				(this.categoryValue == "全部" || item.mainevent == this.categoryValue)
			) {
				isShow = true;
			}
			if (isShow) {
				this.isHaveItem = true;
			}
			return isShow;
		},
		//微信分享方法
		WxSharMenu() {
			let urlStr = encodeURIComponent(this.wxsharelink);
			getShareSignaTure({
				url: urlStr,
			})
				.then((res) => {
					if (res.code == 200) {
						this.appId = res.data.appId;
						this.timestamp = res.data.timesTamp;
						this.nonceStr = res.data.nonceStr;
						this.signature = res.data.signaTure;

						wx.config({
							//注意appId,timestamp,nonceStr,signature四个参数，就是把当前url作为参数请求接口，是接口返回生成的
							debug: false, //生产环境需要关闭debug模式
							appId: this.appId, //appId通过微信服务号后台查看
							timestamp: this.timestamp, //生成签名的时间戳
							nonceStr: this.nonceStr, //生成签名的随机字符串
							signature: this.signature, //签名
							jsApiList: [
								"updateAppMessageShareData",
								"updateTimelineShareData",
							], //需要调用的JS接口列表
						});
						//分享参数，标题，描述，链接，图片title,desc,link,imgUrl
						wx.ready(() => {
							wx.updateAppMessageShareData({
								title: this.sharetitle,
								desc: this.sharedescs,
								link: this.wxsharelink,
								imgUrl: this.shareimg,
								success: function () {
									// 设置成功
									//alert("分享朋友圈设置成功")
								},
							});
							wx.updateTimelineShareData({
								title: this.sharetitle,
								desc: this.sharedescs,
								link: this.wxsharelink,
								imgUrl: this.shareimg,
								success: function () {
									// 设置成功
									//alert(desc)
								},
							});
						});
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
		toggleContent() {
			this.isCollapsed = !this.isCollapsed;
		},
		share(id) {
			let msg = "https://web.metaera.hk/activity/" + id;
			clipboard.copy(msg);
			this.$message({ message: "复制成功", type: "success" });
		},
		showSideDetail(id) {
			// 生成目标路由的 URL
			const routePath = this.$router.resolve({
				name: "/ac_detail",
				params: { id: id },
			}).href;
			// 在新标签页中打开目标路由
			window.open(routePath, "_blank");
		},
		stateClick(item, type) {
			this.state = type;
		},
		formatTime(timestamp) {
			const date = new Date(timestamp);
			let hours = date.getHours();
			const minutes = date.getMinutes();
			const ampm = hours >= 12 ? "PM" : "AM";
			hours = hours % 12;
			hours = hours ? hours : 12; // the hour '0' should be '12'
			const strMinutes = minutes < 10 ? "0" + minutes : minutes;
			return hours + ":" + strMinutes + " " + ampm;
		},
		copyText(msg) {
			clipboard.copy(msg);
			this.$message({ message: "复制成功", type: "success" });
		},
		hideInfo() {
			this.hoverHostIndex = 9999;
			this.hoverCoHostIndex = 9999;
			this.layerStyle = {};
		},
		showInfo(index) {
			this.hoverCoHostIndex = 9999;
			this.hoverHostIndex = index;
			this.$nextTick(() => {
				const peopleList = this.$refs.peopleList_host;
				const listItem = this.$refs.listItem_host[index];

				const layer = listItem.querySelector(
					".ac_detail_ticket_people_list_item"
				);

				const peopleListRect = peopleList.getBoundingClientRect();
				const layerRect = layer.getBoundingClientRect();
				console.log(layerRect.right);
				console.log(peopleListRect.right);

				if (layerRect.right + 300 > this.windowWidth) {
					let overflowWidth = this.windowWidth - peopleListRect.right;
					// overflowWidth = -20;
					console.log(overflowWidth);
					this.layerStyle = {
						right: `-${overflowWidth}px`,
						left: "auto",
					};
				} else {
					this.layerStyle = {
						left: "0",
						right: "auto",
					};
				}
			});
		},
		toggleInfo(index) {
			this.hoverCoHostIndex = 9999;
			this.hoverHostIndex = index;
		},
		coShowInfo(index) {
			this.hoverHostIndex = 9999;
			this.hoverCoHostIndex = index;
			this.$nextTick(() => {
				const peopleList = this.$refs.peopleList;
				const listItem = this.$refs.listItem[index];

				const layer = listItem.querySelector(
					".ac_detail_ticket_people_list_item"
				);

				const peopleListRect = peopleList.getBoundingClientRect();
				const layerRect = layer.getBoundingClientRect();
				console.log(layerRect.right);
				console.log(peopleListRect.right);

				if (layerRect.right + 200 > peopleListRect.right) {
					const overflowWidth = layerRect.right - peopleListRect.right;

					console.log(overflowWidth);
					this.layerStyle = {
						right: `${overflowWidth}px`,
						left: "auto",
					};
				} else {
					this.layerStyle = {
						left: "0",
						right: "auto",
					};
				}
			});
		},
		coToggleInfo(index) {
			this.hoverHostIndex = 9999;
			this.hoverCoHostIndex = index;
		},
		hostMore() {
			this.hostsBool = !this.hostsBool;
		},
		coHostMore() {
			this.coHostsBool = !this.coHostsBool;
		},
		checkLogin() {
			if (this.token == 0 || this.token == undefined) {
				return false;
			} else {
				return true;
			}
		},

		handleScroll(event) {},
		showUrl(url) {
			if (url == undefined || url.length == 0) {
				return;
			}
			window.open(url, "");
		},
		getMonthStr(time) {
			const timeDate = new Date(time);

			const monthNames = [
				"JAN",
				"FEB",
				"MAR",
				"APR",
				"MAY",
				"JUN",
				"JUL",
				"AUG",
				"SEP",
				"OCT",
				"NOV",
				"DEC",
			];

			return monthNames[timeDate.getMonth()];
		},
		getDayStr(time) {
			const timeDate = new Date(time);
			return timeDate.getDate();
		},
		goBack() {
			console.log("123123");

			if (window.history.length > 1) {
				this.$router.go(-1);
			} else {
				this.$router.push("/");
			}
		},
		getAcitvityDetail() {
			let obj = { id: this.id };
			acitvityDetail(obj)
				.then((res) => {
					if (res.code == 200) {
						this.loading = false;
						this.item = res.data;
						this.item.sideeventArray = this.item.sideeventArray.concat(
							res.data.otherSideEventArray
						);
						this.sharetitle = res.data.title;
						this.sharedescs = this.extractPlainText(res.data.content).slice(
							0,
							100
						);

						this.wxsharelink = "https://www.metaera.hk/ac_detail/" + this.id;
						this.hostsArray = JSON.parse(res.data.organizer);
						this.coHostsArray = JSON.parse(res.data.host);

						let tmp = JSON.parse(this.item.ticket);

						this.ticketArray = tmp;
						this.ticket_sel_index = 0;
						this.WxSharMenu();
					} else {
						this.$message.error("获取活动详情失败，请重新获取");
						this.goBack();
					}
				})
				.catch((error) => {
					this.$message.error(error.message);
				});
		},
		extractPlainText(htmlString) {
			var tmp = htmlString.replace(/<[^>]+>/g, "");
			console.log("htmlString", tmp);
			return tmp; // 用正则表达式移除 HTML 标签
		},
		formatDate(dateString) {
			const date = new Date(dateString);
			return date.toLocaleString("zh-CN", {
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
				hour: "2-digit",
				minute: "2-digit",
				hour12: false,
			});
		},
		gotoPrivacy() {
			this.$router.push({
				path: "/copyright",
			});
		},
		handleInput(value) {
			// 验证输入的值是否为正整数
			console.log(value);

			const num = Number(value);
			if (!isNaN(num) && Number.isInteger(num) && num > 0) {
				this.count = num.toString();
			} else {
				this.count = "";
			}
		},
		onCheckClick() {
			this.onCheck = this.onCheck == 0 ? 1 : 0;
		},
		showNext() {
			if (this.ticketArray[this.ticket_sel_index].type == 0) {
				this.$router.push({
					path: "/ac_mine",
				});
			}
		},
		next() {
			if (this.ticketArray[this.ticket_sel_index].type == 0) {
				this.freeTicket();
			} else {
				this.showUrl(this.item.url);
				// this.buyTicket();
			}
		},
		ticketClick(index) {
			this.ticket_sel_index = index;
		},
		goBack() {
			if (window.history.length > 1) {
				this.$router.go(-1);
			} else {
				this.$router.push("/");
			}
		},
		async buyTicket() {
			if (this.onCheck != 1) {
				this.$message.error("请阅读并勾选隐私协议");
				return;
			}
			if (this.count < 1) {
				this.$message.error("请正确输入购票数量");
				return;
			}

			let ticketItem = this.ticketArray[this.ticket_sel_index];
			if (ticketItem["type"] == 1 && this.code.length == 0) {
				this.$message.error("请正确输入折扣码");
				return;
			}
			const stripe = await loadStripe(
				"pk_live_51PYrHdEQq0jGyNkS55zMD21yxNNrl2pZU9nlo2IxhgKcbEgVWQGRyjWhB3pEzzgrDwTxohG54aairByrxCqIg32g00gWroSP0b"
			); // 替换为你的Publishable Key
			let obj = {
				id: this.id,
				type: ticketItem["type"],
				count: this.count,
				code: this.code,
				userid: getUserId(),
			};
			this.$loading.show();
			console.log(obj);

			acitvityOrder(obj)
				.then((res) => {
					this.$loading.hide();
					if (res.code == 200) {
						let ticketId = res.data.ticketId;
						if (ticketId) {
							this.$message({ message: "success", type: "success" });
							this.$router.push({
								path: "/ac_mine",
							});
						} else {
							stripe.redirectToCheckout({
								sessionId: res.data.checkout_session,
							});
						}
					} else {
						this.$message.error(res.message);
					}
				})
				.catch((error) => {
					this.$loading.hide();
					this.$message.error(error.message);
				});
		},
		freeTicket() {
			if (this.onCheck != 1) {
				this.$message.error("请阅读并勾选隐私协议");
				return;
			}
			if (this.count < 1) {
				this.$message.error("请正确输入购票数量");
				return;
			}
			let obj = {
				id: this.id,
				type: 0,
				count: this.count,
				userid: getUserId(),
			};
			this.$loading.show();
			acitvityOrder(obj)
				.then((res) => {
					this.$loading.hide();
					if (res.code == 200) {
						this.showSuccess = true;
					} else {
						this.$message.error(res.message);
					}
				})
				.catch((error) => {
					this.$loading.hide();
					this.$message.error(error.message);
				});
		},
	},
};
</script>

<style scoped>
.ac-detail-pc {
	display: block;
}
.ac-detail-mobile {
	display: none;
}
@media screen and (max-width: 900px) {
	.ac-detail-pc {
		display: none;
	}
	.ac-detail-mobile {
		display: block;
	}
}

.pd_body {
	height: 100vh;
	overflow-y: scroll;
	background: #ffffff;
}
.scroll-container {
	height: 100vh;
	overflow-y: scroll;
}
.js-copy {
	position: absolute;
	top: 0px;
	left: 0;
	width: calc(100vw);
	margin: 0 auto;
	background: white;
}
</style>

<!-- ac_detail_top -->
<style scoped>
.ac_detail_top {
	margin-top: 100px;
	width: 100%;
	background: url(https://res.metaera.hk/resources/assets/images/active/13.png);
	background-size: cover;
	background-position: center;
	overflow: hidden;
	position: relative; /* 为了让伪元素相对于这个容器定位 */
}
.ac_detail_top img {
	margin-top: 80px;
	margin-bottom: 80px;
	width: 60%;
	margin-left: 20%;
	border-radius: 20px;
}
</style>

<!-- ac_detail_ticket -->
<style scoped>
.ac_detail_bg_top {
}

.ac_detail_ticket {
	margin-top: 120px;
	width: 100%;
	display: flex;
}
.ac_detail_ticket_left {
	margin-left: 10%;
	margin-right: 2%;
	margin-top: 80px;
	margin-bottom: 40px;
	width: 38%;
	position: relative;
}

.ac_detail_ticket_left img {
	width: 100%;
	height: auto;
	border-radius: 10px;
}

.ac_calendar_start_hour {
	top: 10px; /* 距离顶部的距离 */
	left: 10px; /* 距离左边的距离 */
	position: absolute;
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 5px;
	padding-bottom: 5px;
	background-color: rgba(0, 0, 0, 0.5); /* 半透明背景 */
	color: white; /* 字体颜色 */
	border-radius: 7px;
	font-family: PingFang SC;
	font-size: 14px;
	font-weight: 400;
	line-height: 14px;
	display: flex;
	align-items: center;
	text-transform: uppercase;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
	display: none;
}

.ac_calendar_line {
	top: 10px; /* 距离顶部的距离 */
	right: 10px; /* 距离右边的距离 */
	position: absolute;
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 5px;
	padding-bottom: 5px;
	background-color: rgba(0, 0, 0, 0.5); /* 半透明背景 */
	color: white; /* 字体颜色 */
	border-radius: 7px;
	font-family: PingFang SC;
	font-size: 14px;
	font-weight: 400;
	line-height: 14px;
	display: flex;
	align-items: center;
	text-transform: uppercase;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
}

.ac_calendar_line_noStart {
	background-color: rgba(255, 255, 255, 0.9); /* 半透明背景 */
	color: #0256ff;
}
.ac_calendar_line_end {
	background-color: rgba(255, 255, 255, 0.9); /* 半透明背景 */
	color: gray;
}

.ac_calendar_line_noStart div {
	width: 10px;
	height: 10px;
	border-radius: 10px;
	margin-right: 10px;
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
}

.ac_calendar_line_progress {
	background-color: rgba(255, 255, 255, 0.9); /* 半透明背景 */
	color: #08bf66;
}
.ac_calendar_line_progress div {
	width: 10px;
	height: 10px;
	border-radius: 10px;
	margin-right: 10px;
	background: #08bf66;
}
.ac_detail_ticket_right {
	width: 38%;
	margin-left: 2%;
	margin-top: 80px;
	margin-bottom: 40px;
	/* display: flex; */
	flex-direction: column;
	justify-content: space-between;
}

.ac_detail_ticket_top {
	display: flex;
}
.ac_detail_content_online,
.ac_detail_ticket_sideevent {
	margin-top: 10px;
	margin-right: 20px;
	height: 30px;
	border-radius: 10px;
	background: white;
	padding-left: 14px;
	padding-right: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: 14px;
	display: inline-flex;
	align-items: center;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	color: #333333;
	border: 1px solid #333333;
}
.ac_detail_ticket_title {
	margin-top: 15px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 28px;
	font-weight: 600;
	line-height: 34px;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 黑色主色（字段一级） */
	color: #333333;

	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	text-overflow: ellipsis;
	-webkit-box-orient: vertical;
}

.ac_detail_ticket_specialCode {
	margin-top: 20px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 20px;
	font-weight: 400;
	line-height: 34px;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 黑色主色（字段一级） */
	color: #333333;

	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	text-overflow: ellipsis;
	-webkit-box-orient: vertical;
}
.ac_detail_ticket_date {
	display: flex;
	margin-top: 10px;
}

.ac_detail_ticket_calendar {
	width: 100px;
	/* height: 85px; */
}

.ac_detail_ticket_calendar_m {
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
	width: 100px;
	height: 33px;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	color: white;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 18px;
	font-weight: bold;
	/* display: flex; */
	align-content: center;
	align-items: center;
	text-align: center;
}
.ac_detail_ticket_calendar_d {
	background: #e6e4e2;
	width: 100%;
	height: 50px;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	color: #333333;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 34px;
	font-weight: bold;
	/* display: flex; */
	align-content: center;
	align-items: center;
	text-align: center;
}

.ac_detail_ticket_info {
	margin-left: 0px;
	margin-top: 10px;
}
.ac_detail_ticket_info_item {
	display: flex;
	margin-bottom: 18px;
	text-align: center;
	align-content: center;
	align-items: center;
}
.ac_detail_ticket_info_item_btn {
	display: flex;
}
.ac_detail_ticket_info_item_share {
	height: 44px;
	display: flex;
	align-items: center;
	padding-left: 25px;
	padding-right: 10px;
	border-radius: 10px;
	text-align: center;
	align-content: center;

	border-radius: 20px;
	opacity: 1;
	box-sizing: border-box;
	border: 1.5px solid #333333;
	margin-right: 30px;
}

.ac_detail_ticket_info_item_share_people {
	background: #333333;
	border: 1.5px solid #ffffff;
}

.ac_detail_ticket_info_item_share_img {
	aspect-ratio: 1 / 1;
	height: 60%;
	margin-right: 10px;
	background-size: cover;
	background-position: center;
	background-image: url("https://res.metaera.hk/resources/assets/images/home/share_b.png");
}

.ac_detail_ticket_info_item_share_img_people {
	background-image: url("https://res.metaera.hk/resources/assets/images/home/share_people.png");
}

.ac_detail_ticket_info_item_share_text {
	color: #333333;
	font-size: 18px;
	font-weight: 500;
}

.ac_detail_ticket_info_item_share_text_people {
	color: #ffffff;
}

.ac_detail_ticket_info_item_share:hover .ac_detail_ticket_info_item_share_img {
	background-image: url("https://res.metaera.hk/resources/assets/images/home/share_w.png");
}

.ac_detail_ticket_info_item_share:hover
	.ac_detail_ticket_info_item_share_img_people {
	background-image: url("https://res.metaera.hk/resources/assets/images/home/share_people.png");
}

.ac_detail_ticket_info_item_share:hover span {
	color: #ffffff;
}

.ac_detail_ticket_info_item_share:hover {
	background: #a855f7;
	border: 1.5px solid #a855f7;
}

.ac_detail_ticket_info_item_share_people:hover {
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
	border: 1.5px solid #ffffff;
}

.ac_detail_ticket_info_item_time {
	display: block;
}

.ac_detail_ticket_info_item_time_div {
	display: none;
}

.ac_detail_ticket_info_item_bg {
	text-align: center;
	align-content: center;
	align-items: center;
	height: 40px;
	margin-left: 12px;
	padding-right: 12px;
	background: rgba(125, 156, 247, 0.1);
	border-radius: 10px;
}

.ac_detail_ticket_info_item_bg img {
	margin-left: 10px !important;
	margin-top: -5px;
}

.ac_detail_ticket_info_item_bg span {
	font-family: "Bison-Bold" !important;
	font-size: 24px !important;
	font-weight: 300;
	line-height: 24px !important;
	letter-spacing: 0.1em;
	color: #0256ff !important;
}

.ac_detail_ticket_info_item_bg_copy {
	height: 30px;
}

.ac_detail_ticket_info_item_bg_copy span {
	font-size: 14px !important;
}

.ac_detail_ticket_info_item_bg_copy img {
	width: 12px !important;
	height: 12px !important;
	object-fit: cover;
}

.ac_detail_ticket_info_item img {
	width: 16px;
	height: 16px;
	object-fit: contain;
}

.ac_detail_ticket_info_item span,
ac_detail_ticket_info_item_time_div div {
	margin-left: 12px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 14px;
	font-weight: 500;
	line-height: 14px;
	letter-spacing: 0em;
	text-align: left;
	font-variation-settings: "opsz" auto;
	/* 正文色/正文色 */
	color: #1a1a1a;
}

.ac_detail_ticket_info_item_url {
	color: #0256ff !important;
}

.ac_detail_specialCode {
	display: none;
}

.ac_detail_ticket_people {
	margin-left: 0%;
	margin-right: 0%;
	margin-top: -10px;
	margin-bottom: 20px;
	display: flex;
	justify-content: space-between;
	/* margin-top: auto; */
	display: flex;
	flex: 0 1 auto;
}

.ac_detail_ticket_people_title {
	margin-bottom: 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.ac_detail_ticket_people_item {
	width: 48%;
	display: flex;
}

.ac_detail_ticket_people_title_1 {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 16px;
	font-weight: 600;
	line-height: 16px;
	letter-spacing: 0em;
	width: 50px;
	/* 黑色主色（字段一级） */
	color: #333333;
	margin-top: 12px;
	margin-right: 10px;
}
.ac_detail_ticket_people_title_2 {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 12px;
	font-weight: normal;
	line-height: 12px;
	letter-spacing: 0em;
	display: none;
	font-variation-settings: "opsz" auto;
	/* 正文色/正文色 */
	color: #1a1a1a;
}

.ac_detail_ticket_people_title_2 img {
	width: 12px;
	height: auto;
}

.ac_detail_ticket_people_list {
	width: 100%;
	position: relative;
	display: flex;
	flex-wrap: wrap; /* 允许换行 */
	gap: 10px; /* 设置子项之间的间距 */
}
.ac_detail_ticket_people_list_hide {
	/* height: 60px; */
	/* overflow: hidden; */
}

.host-item-wrapper {
	position: relative; /* 确保浮层相对于这个元素定位 */
}
.ac_detail_ticket_people_list_item {
	position: relative;
	display: inline-block;
	margin-top: 8px;
	display: flex;
	align-items: center;
	flex-wrap: wrap; /* 允许文本换行 */
}

.ac_detail_ticket_people_list_item img {
	width: 24px;
	height: 24px;
	opacity: 1;
	object-fit: cover;
	border-radius: 20px;
	box-sizing: border-box;
	border: 1px solid #e2ebff;
	flex-shrink: 0; /* 防止图像缩小 */
}

.ac_detail_ticket_people_list_item_layer {
	position: absolute;
	top: 100%;
	left: 0;
	background: white;
	padding: 30px;
	border-radius: 20px;
	margin-top: 5px;
	z-index: 99;
	width: 300px;
	/* height: 100px; */
	box-sizing: border-box;
	box-shadow: 0px 4px 46px 0px rgba(73, 73, 73, 0.16);
}

.ac_detail_ticket_people_list_item_layer_img {
	margin-top: 0px;
}
.ac_detail_ticket_people_list_item_layer_img img {
	width: 40px;
	height: 40px;
	border-radius: 20px;
}

.ac_detail_ticket_people_list_item_layer_name {
	margin-top: 23px;
	font-family: PingFang SC;
	font-size: 18px;
	font-weight: normal;
	line-height: 18px;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	color: #333333;
}
.ac_detail_ticket_people_list_item_layer_content {
	margin-top: 20px;
	font-family: PingFang SC;
	font-size: 16px;
	font-weight: normal;
	line-height: 24px;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	color: #333333;

	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 4;
	text-overflow: ellipsis;
	-webkit-box-orient: vertical;
}

.ac_detail_ticket_people_list_item_layer_url {
	display: flex;
	margin-top: 20px;
}

.ac_detail_ticket_people_list_item_layer_url img {
	width: 26px;
	height: 26px;
	margin-right: 30px;
}
</style>

<!-- ac_detail_rigister -->
<style scoped>
.ac_detail_rigister {
	margin-left: 10%;
	margin-right: 10%;
	margin-bottom: 50px;
	margin-top: 0px;
	width: 80%;
	border-radius: 20px;
	padding-top: 24px;
	padding-bottom: 20px;
	background: rgba(245, 246, 250, 0.5);
	text-align: center;
}
.ac_detail_rigister_title,
.ac_detail_rigister_title span {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 20px;
	font-weight: normal;
	line-height: 20px;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 黑色主色（字段一级） */
	color: #333333;
}
.ac_detail_rigister_info {
	display: inline-block;
	height: 52px;
	border-radius: 13px;
	align-items: center;
	align-content: center;
	margin-top: 25px;
	margin-bottom: 20px;
	box-sizing: border-box;
	/* 主要色/主要色 */
	border: 1px solid #0256ff;
}
.ac_detail_rigister_info img {
	border-radius: 40px;
	margin-left: 20px;
	width: 36px;
	height: 36px;
	opacity: 1;
	box-sizing: border-box;
	border: 1px solid #e2ebff;
}

.ac_detail_rigister_info span {
	margin-left: 10px;
	margin-right: 20px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 16px;
	font-weight: normal;
	line-height: 16px;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 黑色主色（字段一级） */
	color: #333333;
}

.ac_detail_rigister_action {
	width: 100%;
	height: 50px;
	text-align: center;
	align-content: center;
	align-items: center;
}

.ac_detail_select_time {
	margin-top: 20px;
	display: inline-flex;
	text-align: center;
	align-content: center;
	align-items: center;
}

.ac_detail_select_time_title {
	font-family: PingFang SC;
	font-size: 14px;
	font-weight: 400;
	line-height: 14px;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 333 */
	color: #333333;
}

.ac_detail_select_date {
	margin-top: 0px;
	font-family: PingFang SC;
	font-size: 14px;
	font-weight: 500;
	line-height: 14px;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 333 */
	color: #333333;
}
.ac_detail_rigister_action span {
	border-radius: 40px;
	opacity: 1;
	padding-left: 40px;
	padding-right: 40px;
	padding-top: 10px;
	padding-bottom: 10px;
	/* 主要色/主要色 */
	/* background:linear-gradient(45deg, #9945FF,#8752F3,#5497D5,#43B4CA,#28E0B9,#19FB9B); */
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 18px;
	font-weight: normal;
	line-height: 16px;
	text-align: center;
	align-items: center;
	align-content: center;
	/* text-transform: uppercase; */
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	color: #ffffff;
}
</style>

<!-- ac_detail_login -->
<style scoped>
.ac_detail_login_bg {
	width: 100%;
	margin-left: 0px;
	margin-right: 0px;
	display: none;
}
.ac_detail_login {
	margin-left: 10%;
	margin-right: 10%;
	margin-bottom: 50px;
	margin-top: 0px;
	border-radius: 20px;
	padding-top: 24px;
	padding-bottom: 24px;
	background: rgba(245, 246, 250, 0.5);
	text-align: center;
}
.ac_detail_login_title {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 20px;
	font-weight: normal;
	line-height: 25px;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 黑色主色（字段一级） */
	color: #333333;
}

.ac_detail_login_action {
	width: 100%;
	height: 40px;
	margin-top: 20px;
	text-align: center;
	align-content: center;
	align-items: center;
}

.ac_detail_login span {
	width: 290px;
	border-radius: 40px;
	opacity: 1;
	padding-left: 40px;
	padding-right: 40px;
	padding-top: 10px;
	padding-bottom: 10px;
	/* 主要色/主要色 */
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);

	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 18px;
	font-weight: normal;
	line-height: 16px;
	text-align: center;
	align-items: center;
	align-content: center;
	/* text-transform: uppercase; */
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	color: #ffffff;
}
</style>

<!-- ac_detail_overview -->
<style scoped>
.ac_detail_overview {
	width: 80%;
	margin-left: 10%;
	margin-bottom: 50px;
	margin-top: 20px;
	background: #f5f6fa;
}
.ac_detail_overview_header {
	margin-left: 0px;
	margin-right: 0px;
	display: flex;
	height: 62px;
	justify-content: space-between;
	border-radius: 10px;
	background: #e4e8f6;
}

.ac_detail_overview_title_expand {
	align-items: center;
	align-content: center;
	margin-right: 30px;
}
.ac_detail_overview_title_expand img {
	width: 24px;
	height: 24px;
	object-fit: cover;
}
.ac_detail_overview_title_new {
	opacity: 1;

	font-size: 24px;
	font-weight: bold;
	line-height: 24px;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 正文色/正文色 */
	color: #1a1a1a;
	align-items: center;
	align-content: center;
	padding-left: 30px;
}

.ac_detail_overview_title {
	margin-left: 0%;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 28px;
	font-weight: normal;
	line-height: 28px;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #1a1a1a;
	text-decoration: underline;
	text-decoration-color: #0256ff; /* 设置下划线颜色 */
	text-decoration-thickness: 4px; /* 设置下划线高度 */
	text-underline-offset: 5px; /* 设置下划线与文字之间的距离 */
}
.collapsed {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}
.expanded {
	max-height: none; /* 展开时高度不限 */
}
.ac_detail_overview_content {
	margin-top: 0px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 18px;
	font-weight: normal;
	line-height: 34px;
	letter-spacing: 0em;
	text-align: justify;
	font-variation-settings: "opsz" auto;
	color: #333333;
	margin-left: 0px;
	margin-right: 0px;
	padding-left: 20px;
	padding-right: 20px;
	background: #f5f6fa;
	overflow: hidden;
	transition: max-height 0.3s ease;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
}

.ac_detail_overview_line {
	height: 1px;
	border-radius: 10px;
	opacity: 1;
	box-sizing: border-box;
	border: 1px solid #bfbfbf;
}
</style>
<style scoped>
@media screen and (max-width: 900px) {
	.pd_body {
		height: 100vh;
		overflow-y: scroll;
		background: #f7f8f9;
	}
	.ac_detail_bg_top {
		margin-left: 18px;
		margin-right: 18px;
		border-radius: 10px;
		background: white;
		padding-bottom: 10px;
	}
	.ac_detail_ticket {
		margin-top: 20px;
		width: 100%;
		display: block;
	}
	.ac_detail_content_online,
	.ac_detail_ticket_sideevent {
		margin-top: 0px;
	}
	.ac_detail_ticket_left {
		margin-left: 0%;
		margin-right: 0%;
		margin-top: 80px;
		margin-bottom: 10px;
		width: 100%;
	}

	.ac_detail_ticket_right {
		width: 90%;
		margin-left: 5%;
		margin-top: 20px;
		margin-bottom: 20px;
	}

	.ac_detail_ticket_title {
		font-size: 24px;
		line-height: 28px;
	}

	.ac_detail_ticket_date {
		display: flex;
		margin-top: 20px;
	}
	.ac_detail_ticket_info {
		margin-left: 0px;
		margin-top: 0px;
	}
	.ac_detail_select_time {
		margin-top: 10px;
		display: block;
	}
	.ac_detail_select_time_title {
		font-size: 12px;
	}
	.ac_detail_select_date {
		margin-top: 6px;
		font-size: 12px;
	}
	.ac_detail_specialCode {
		display: block;
		padding-top: 20px;
		padding-bottom: 20px;
		text-align: center;

		margin-left: 18px;
		margin-right: 18px;

		margin-top: 20px;
		border-radius: 10px;
		opacity: 1;
		background: #ffffff;
		box-shadow: 0px 22px 31px 0px rgba(54, 62, 147, 0.05);
	}

	.ac_detail_specialCode div {
		width: 100%;
	}

	.ac_detail_specialCode_title span {
		font-family: PingFang SC;
		font-size: 18px;
		font-weight: 600;
		line-height: 18px;
		letter-spacing: 0em;

		font-variation-settings: "opsz" auto;
		/* 正文色/正文色 */
		color: #1a1a1a;
	}
	.ac_detail_specialCode_code {
		margin-top: 20px;
	}
	.ac_detail_specialCode_code span {
		display: inline-flex;
		align-content: center;
		align-items: center;
		padding-left: 20px;
		padding-right: 20px;
		border-radius: 10px;
		height: 40px;
		font-family: "Bison-Bold" !important;
		font-size: 24px !important;
		font-weight: 300;
		line-height: 24px !important;
		letter-spacing: 0.1em;
		color: #0256ff !important;
		background: rgba(125, 156, 247, 0.1);
	}

	.ac_detail_specialCode_btn {
		margin-top: 20px;
		margin-bottom: 6px;
	}
	.ac_detail_ticket_info_item_time {
		display: none;
	}

	.ac_detail_ticket_info_item_time_div {
		display: block;
		margin-left: -8px;
	}
	.ac_detail_specialCode_btn span {
		display: inline-block;
		align-content: center;
		align-items: center;
		text-align: center;
		padding-left: 20px;
		padding-right: 20px;
		border-radius: 10px;
		height: 40px;
		width: 190px;
		border-radius: 40px;
		opacity: 1;
		color: #ffffff;
		font-family: PingFang SC;
		font-size: 12px;
		font-weight: normal;
		line-height: 12px;
		/* 渐变主色2 */
		background: linear-gradient(
			45deg,
			#9945ff,
			#8752f3,
			#5497d5,
			#43b4ca,
			#28e0b9,
			#19fb9b
		);
	}

	.ac_detail_ticket_calendar_m {
		background: linear-gradient(
			45deg,
			#9945ff,
			#8752f3,
			#5497d5,
			#43b4ca,
			#28e0b9,
			#19fb9b
		);
		width: 100px;
		height: 40px;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		font-size: 14px;
	}

	.ac_detail_ticket_info_item {
		margin-bottom: 12px;
	}

	.ac_detail_ticket_info_item1 {
		display: none;
	}
	.ac_detail_ticket_info_item_share {
		height: 30px;
		padding-left: 10px;
		padding-right: 10px;
		margin-right: 10px;
	}

	.ac_detail_overview_header {
		background: #ffffff;
		margin-left: 20px;
		margin-right: 20px;
		display: flex;
		padding-top: 10px;
		height: 40px;
		justify-content: space-between;
		border-radius: 0px;
		border-top-right-radius: 10px;
		border-top-left-radius: 10px;
	}

	.ac_detail_ticket_info_item_share_text {
		font-size: 14px;
		font-weight: 500;
	}
	.ac_detail_ticket_info_item_bg {
		text-align: center;
		align-content: center;
		align-items: center;
		height: 30px;
		margin-left: 8px;
		padding-right: 8px;
		background: rgba(125, 156, 247, 0.1);
		border-radius: 10px;
	}

	.ac_detail_ticket_info_item_bg img {
		margin-left: 6px !important;
		margin-top: -5px;
	}

	.ac_detail_ticket_info_item_bg span {
		font-family: "Bison-Bold" !important;
		font-size: 18px !important;
		font-weight: 300;
		line-height: 18px !important;
		letter-spacing: 0.1em;
		color: #0256ff !important;
	}

	.ac_detail_ticket_info_item img {
		width: 14px;
		height: 14px;
		object-fit: contain;
	}
	.ac_detail_ticket_info_item span,
	.ac_detail_ticket_info_item_time_div div {
		margin-left: 8px;
		font-size: 12px;
	}

	.ac_detail_ticket_people {
		margin-left: 0%;
		margin-right: 0%;
		margin-top: 0px;
		margin-bottom: 0px;
		display: flex;
		justify-content: space-between;
		/* margin-top: auto; */
		display: flex;
		flex: 0 1 auto;
	}
	.ac_detail_ticket_people_list_hide {
		height: 40px;
		overflow: hidden;
	}
	.ac_detail_ticket_people_title_1 {
		font-size: 14px;
		font-weight: 600;
		line-height: 16px;
	}

	.ac_detail_ticket_people_list_item {
		margin-top: 4px;
	}

	.ac_detail_ticket_people_list_item img {
		width: 30px;
		height: 30px;
	}

	.ac_detail_login_bg {
		width: 100%;
		margin-left: 0px;
		margin-right: 0px;
	}

	.ac_detail_login {
		margin-left: 18px;
		margin-right: 18px;
		margin-bottom: 20px;
		margin-top: 20px;
		border-radius: 10px;
		padding-top: 18px;
		padding-bottom: 18px;
		background: #ffffff;
	}

	.ac_detail_login_title {
		font-size: 16px;
		line-height: 20px;
	}

	.ac_detail_login_action {
		width: 100%;
		height: 30px;
		margin-top: 20px;
	}

	.ac_detail_login span {
		border-radius: 40px;
		padding-top: 10px;
		padding-bottom: 10px;
		font-size: 12px;
	}

	.ac_detail_overview {
		width: 100%;
		margin-left: 0%;
		margin-bottom: 30px;
	}

	.ac_detail_overview_title {
		margin-left: 5%;
		font-size: 20px;
		line-height: 28px;
	}

	.ac_detail_overview_content {
		margin-top: 0px;
		margin-left: 0px;
		margin-right: 0px;
		/* border-radius: 10px; */
	}

	.ac_detail_rigister {
		margin-top: 20px;
		margin-left: 5%;
		margin-right: 5%;
		margin-bottom: 20px;
		width: 90%;
		border-radius: 10px;
		padding-top: 14px;
		padding-bottom: 14px;
		background: #ffffff;
	}
	.ac_detail_rigister_title,
	.ac_detail_rigister_title span {
		font-size: 16px;
	}
	.ac_detail_rigister_info {
		height: 42px;
		border-radius: 10px;
		margin-top: 15px;
		margin-bottom: 10px;
	}
	.ac_detail_rigister_info img {
		border-radius: 30px;
		margin-left: 10px;
		width: 30px;
		height: 30px;
	}

	.ac_detail_rigister_info span {
		margin-left: 10px;
		margin-right: 20px;
		font-size: 14px;
		line-height: 16px;
	}

	.ac_detail_rigister_action {
		width: 100%;
		height: 60px;
	}

	.ac_detail_rigister_action span {
		border-radius: 40px;
		opacity: 1;
		padding-left: 40px;
		padding-right: 40px;
		padding-top: 10px;
		padding-bottom: 10px;
		font-size: 16px;
	}
}
</style>

<!-- ac_ticket_select -->
<style scoped>
.ac_ticket_select {
	margin-left: 10%;
	margin-right: 10%;
	margin-top: 70px;
	margin-bottom: 40px;
	padding-top: 0px;
}

.ac_ticket_select_title {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 28px;
	font-weight: normal;
	line-height: 28px;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #1a1a1a;
	text-decoration: underline;
	text-decoration-color: #0256ff; /* 设置下划线颜色 */
	text-decoration-thickness: 4px; /* 设置下划线高度 */
	text-underline-offset: 10px; /* 调整下划线与文字的距离 */
}

.ac_ticket_select_bg {
	background: white;
	border-radius: 10px;
}

.ac_ticket_select_user {
	display: inline-flex;
	padding-right: 20px;
	margin-top: 35px;
	height: 52px;
	border-radius: 13px;
	/* 正文色/正文色 */
	background: rgba(26, 26, 26, 0.1);
}

.ac_ticket_select_user img {
	margin-left: 13px;
	margin-top: 6px;
	width: 40px;
	height: 40px;
	opacity: 1;
	border-radius: 20px;
}

.ac_ticket_select_user span {
	margin-left: 10px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 16px;
	font-weight: normal;
	line-height: 16px;
	letter-spacing: 0em;
	align-content: center;
	align-items: center;
	font-variation-settings: "opsz" auto;
	/* 黑色主色（字段一级） */
	color: #333333;
	display: flex;
}
.ac_ticket_list {
	margin-top: 30px;
	display: flex;
}

.ac_ticket_item {
	position: relative; /* 设置相对定位 */
	display: inline-block; /* 使每个项目成为行内块元素 */
	margin-right: clamp(20px, calc(100vw * 40 / 1280), 80px);
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 8px;
	padding-bottom: 8px;
	border-radius: 14px;
	border: 1px solid rgba(26, 26, 26, 0);
}

.ac_ticket_item_type {
	position: absolute; /* 设置绝对定位 */
	width: 12vw;
	bottom: 55%; /* 将文字放置在图片的底部 */
	left: 8%; /* 将文字水平居中 */
	color: white; /* 设置文字颜色 */
	padding: 5px 10px; /* 设置内边距 */
	border-radius: 5px; /* 设置圆角 */
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: clamp(10px, calc(100vw * 14 / 1280), 20px);
	display: -webkit-box; /* 使用 Webkit 的盒模型 */
	-webkit-box-orient: vertical; /* 垂直排列子元素 */
	-webkit-line-clamp: 2; /* 限制最多显示两行 */
	overflow: hidden; /* 隐藏超出容器的内容 */
	text-overflow: ellipsis; /* 使用省略号表示被截断的文本 */
	white-space: normal; /* 允许换行 */
}

.ac_ticket_item_name {
	position: absolute; /* 设置绝对定位 */
	width: 12vw;
	bottom: 20%; /* 将文字放置在图片的底部 */
	left: 8%; /* 将文字水平居中 */
	color: white; /* 设置文字颜色 */
	padding: 5px 10px; /* 设置内边距 */
	border-radius: 5px; /* 设置圆角 */
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: clamp(10px, calc(100vw * 12 / 1280), 20px);
	display: -webkit-box; /* 使用 Webkit 的盒模型 */
	-webkit-box-orient: vertical; /* 垂直排列子元素 */
	-webkit-line-clamp: 2; /* 限制最多显示两行 */
	overflow: hidden; /* 隐藏超出容器的内容 */
	text-overflow: ellipsis; /* 使用省略号表示被截断的文本 */
	white-space: normal; /* 允许换行 */
}

.ac_ticket_item_sel {
	border: 1px solid #336bf6;
}
.ac_ticket_item img {
	display: block; /* 确保图片是块级元素 */
	height: clamp(80px, calc(100vw * 80 / 1280), 140px);
	width: auto;
}

.ac_ticket_option {
	margin-top: 50px;
	display: flex;
	justify-content: center;
}

.ac_ticket_option_item {
	margin-left: 25px;
	margin-right: 25px;
	width: 320px;
	height: 44px;
	border-radius: 40px;
	opacity: 1;

	/* 主要色/主要色 */
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);

	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 18px;
	font-weight: normal;
	line-height: 16px;
	text-align: center;
	align-items: center;
	align-content: center;
	/* text-transform: uppercase; */
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	color: #ffffff;
	display: grid;
}

.ac_ticket_select_num {
	display: flex;
	margin-top: 30px;
}
.ac_ticket_select_item {
	margin-right: 50px;
}
.ac_ticket_select_item_text {
	margin-bottom: 15px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 18px;
	font-weight: 400;
	line-height: 18px;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	color: #333333;
}

.ac_ticket_select_time {
	margin-top: 40px;
}

.ac_ticket_select_time_title {
	font-family: PingFang SC;
	font-size: 18px;
	font-weight: 500;
	line-height: 18px;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 333 */
	color: #333333;
}

.ac_ticket_select_date {
	margin-top: 20px;
	font-family: PingFang SC;
	font-size: 16px;
	font-weight: 500;
	line-height: 16px;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 333 */
	color: #333333;
}

.ac_ticket_select_price {
	margin-top: 50px;
}
.ac_ticket_select_price_title {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 18px;
	font-weight: 600;
	line-height: 18px;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	color: #333333;
}

.ac_ticket_select_price_amount {
	margin-top: 15px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 50px;
	font-weight: 500;
	line-height: 50px;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 主要色/主要色 */
	color: #0256ff;
}

.ac_ticket_select_check {
	margin-top: 20px;
}

.ac_ticket_select_check img {
	width: 14px;
	height: 14px;
}
.ac_ticket_select_check_text {
	margin-left: 10px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 400;
	font-size: 12px;
	color: #333333;
}

.ac_ticket_select_check_text_item {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	font-size: 12px;
	font-variation-settings: "opsz" auto;
	color: #336bf6;
}

.ac_ticket_space_bottom {
	height: 110px;
}
</style>

<style scoped>
@media screen and (max-width: 900px) {
	.ac_detail_top {
		margin-top: 40px;
		width: 100%;
	}

	.ac_ticket_select_title {
		font-size: 18px;
		line-height: 18px;
	}

	.ac_detail_top img {
		margin-top: 40px;
		margin-bottom: 40px;
		width: 80%;
		margin-left: 10%;
		border-radius: 20px;
	}

	.ac_ticket_content {
		width: 90%;
		margin-left: 5%;
		margin-top: 20px;
	}

	.ac_ticket_content_box1 {
		display: block;
		margin-top: 10px;
		align-items: center;
	}

	.ac_ticket_overview_content {
		margin-top: 20px;
		border-radius: 10px;
		background: rgba(216, 216, 216, 0.3);
		padding-left: 10px;
		padding-right: 10px;
		padding-top: 0px;
		padding-bottom: 10px;
	}

	.ac_ticket_overview_content_item {
		font-size: 12px;
		line-height: 14px;
	}
	.ac_ticket_select_bg {
		margin-top: 30px;
		padding-top: 1px;
		padding-bottom: 18px;
		padding-left: 18px;
		padding-right: 18px;
	}
	.ac_ticket_select {
		margin-left: 18px;
		margin-right: 18px;
		margin-top: 20px;
	}

	.ac_ticket_select_user {
		display: inline-flex;
		margin-top: 20px;
		height: 40px;
		border-radius: 6px;
	}

	.ac_ticket_select_user img {
		margin-left: 10px;
		margin-top: 5px;
		width: 30px;
		height: 30px;
	}

	.ac_ticket_list {
		margin-top: 20px;
		display: block;
	}

	.ac_ticket_select_price {
		margin-top: 30px;
	}
	.ac_ticket_space_bottom {
		height: 40px;
	}
	.ac_ticket_option {
		margin-top: 20px;
		display: flex;
		justify-content: center;
	}

	.ac_ticket_option_item {
		height: 32px;
		font-size: 14px;
		line-height: 14px;
	}
	.ac_ticket_item img {
		display: block; /* 确保图片是块级元素 */
		height: auto;
		width: 100%;
	}

	.ac_ticket_item_type {
		position: absolute; /* 设置绝对定位 */
		width: 60vw;
		bottom: 55%; /* 将文字放置在图片的底部 */
		left: 8%; /* 将文字水平居中 */
		color: white; /* 设置文字颜色 */
		padding: 5px 10px; /* 设置内边距 */
		border-radius: 5px; /* 设置圆角 */
		font-size: clamp(10px, calc(100vw * 18 / 414), 20px);
	}

	.ac_ticket_item_name {
		position: absolute; /* 设置绝对定位 */
		width: 60vw;
		bottom: 20%; /* 将文字放置在图片的底部 */
		left: 8%; /* 将文字水平居中 */
		color: white; /* 设置文字颜色 */
		padding: 5px 10px; /* 设置内边距 */
		border-radius: 5px; /* 设置圆角 */
		font-size: clamp(10px, calc(100vw * 16 / 414), 20px);
	}
}
</style>
<!-- ac_detail_filter -->
<style scoped>
.ac_detail_filter {
	width: 80%;
	margin-left: 10%;
	margin-bottom: 100px;
}

.ac_detail_filter_content {
	padding-top: 20px;
	border-radius: 10px;
	opacity: 1;
	box-sizing: border-box;
	margin-left: 0px;
	margin-right: 0px;
}

.ac_detail_filter_state,
.ac_detail_filter_type {
	margin-left: 40px;
	margin-right: 40px;
	display: flex;
	margin-top: 0px;
	margin-bottom: 0px;
}

.ac_detail_filter_title {
	font-size: clamp(16px, calc(100vw * 16 / 1280), 20px);
	font-weight: 500;
	line-height: 20px;
	text-align: center;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	color: #333333;
	margin-right: 30px;
	padding-left: 20px;
	padding-top: 6px;
	padding-bottom: 6px;
	padding-right: 20px;
	margin-bottom: 30px;
}

.ac_detail_filter_title_sel {
	border-radius: 10px;
	opacity: 1;
	background: #333333;
	color: white;
}

.ac_detail_filter_maintitle {
	width: 15%;
}
.ac_detail_filter_item_content {
	width: 86%;
	display: flex;
	flex-wrap: wrap;
}

.ac_detail_side_content {
	margin-left: 0px;
	margin-right: 0px;
}

.ac_detail_side_content_item {
	margin-top: 50px;
	height: 300px;
	display: flex;
	border-radius: 10px;
	opacity: 1;
}
.ac_detail_side_content_item_pic {
	height: 100%;
	width: auto;
}

.ac_detail_side_content_item_pic img {
	height: 100%;
	width: auto;
	object-fit: cover;
	border-radius: 10px;
}
.ac_detail_side_content_item_info {
	margin-left: 20px;
}

@media screen and (max-width: 900px) {
	.ac_detail_filter {
		width: 100%;
		margin-left: 0%;
	}

	.ac_detail_filter_content {
		margin-left: 20px;
		margin-right: 20px;
	}

	.ac_detail_filter_state,
	.ac_detail_filter_type {
		margin-left: 0px;
		margin-right: 0px;
	}

	.ac_detail_filter_title {
		font-size: clamp(16px, calc(100vw * 16 / 1280), 20px);
		line-height: 20px;
		margin-right: 10px;
		padding-top: 6px;
		padding-bottom: 6px;
		padding-right: 10px;
		padding-left: 10px;
		margin-bottom: 10px;
	}

	.ac_detail_side_content {
		margin-left: 20px;
		margin-right: 20px;
	}

	.ac_detail_side_content_item {
		margin-top: 20px;
		display: block;
		height: auto;
		border-radius: 10px;
		opacity: 1;
	}
	.ac_detail_side_content_item_pic {
		height: auto;
		width: 100%;
	}

	.ac_detail_side_content_item_pic img {
		height: auto;
		width: 100%;
		object-fit: cover;
		border-radius: 10px;
	}
	.ac_detail_side_content_item_info {
		margin-left: 20px;
	}
}
</style>

<style scoped>
.ac_detail_filter_content .ac_calendar_filter_header {
	color: black;
	font-size: 16px;
	font-weight: 600;
	display: flex;
	justify-content: space-between;
}
.ac_detail_filter_content .ac_calendar_filter_time {
	margin-top: 20px;
}
.ac_detail_filter_content .ac_calendar_filter_time span {
	color: black;
	font-size: 14px;
	font-weight: 600;
	margin-right: 20px;
}
.ac_category_list_wrapper_detail {
	margin-top: 10px;
	width: 100%; /* 容器宽度 */
	overflow-x: auto; /* 启用横向滚动 */
	white-space: nowrap; /* 防止子元素换行 */
	-webkit-overflow-scrolling: touch; /* 平滑滚动，适配移动端 */
}

.ac_category_list_wrapper_detail .ac_category_list {
	display: flex; /* 子元素横向排列 */
	gap: 10px; /* 按钮之间的间距 */
	padding: 10px;
}

.ac_category_list_wrapper_detail .ac_category_list_item {
	height: 30px;
	padding-left: 15px;
	padding-right: 15px;
	background: white;
	color: #333;
	border-radius: 10px;
	text-align: center;
	align-content: center;
	align-items: center;
}

.ac_category_list_wrapper_detail .ac_category_list_item_sel {
	background: black;
	color: white;
}
</style>
<style>
.ac_detail_overview_content_mobile .ql-editor {
	background: white !important;
}
</style>
