<template>
	<div class="ac_success_overlay" @click="close()">
		<div class="ac_success">
			<div class="ac_success_img">
				<img
					src="https://res.metaera.hk/resources/assets/images/active/46.png"
				/>
			</div>
			<div class="ac_success_info_title"> {{ tipTitle }} </div>
			<div class="ac_success_info_subtitle">
				{{ tipMessage }}
			</div>
			<div class="ac_success_bottom">
				<span class="ac_success_bottom_btn pointer" @click="showNext()">
					NEXT
				</span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "",
	props: {
		tipTitle: "",
		tipMessage: "",
	},
	created() {},
	data() {
		return {};
	},
	methods: {
		close() {
			this.$emit("close");
		},

		showNext() {
			this.$emit("showNext");
		},
	},
	watch: {},
};
</script>

<style scoped>
.ac_success_overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
}
.ac_success {
	width: 380px;
	padding: 20px;
	border-radius: 20px;
	opacity: 1;
	background: #ffffff;
	align-items: center;
	text-align: center;
	align-content: center;
	display: ruby;
}
</style>

<style scoped>
.ac_success_img {
	width: 146px;
	height: 146px;
	display: inline-flex;
	text-align: center;
}
.ac_success_img img {
	width: 100%;
	height: 100%;
}

.ac_success_info_title {
	font-family: PingFang SC;
	font-size: 18px;
	font-weight: normal;
	line-height: 18px;
	text-align: center;
	letter-spacing: 0em;
	width: 100%;
	font-variation-settings: "opsz" auto;
	/* 一级颜色 */
	color: #000000;
}

.ac_success_info_subtitle {
	margin-top: 20px;
	font-family: PingFang SC;
	font-size: 14px;
	font-weight: normal;
	line-height: 22px;
	text-align: center;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 正文色/正文辅助色 */
	color: #767676;
}
</style>
<!-- ac_success_bottom -->
<style scoped>
.ac_success_bottom {
	margin-top: 20px;
	height: 54px;
	width: 100%;
	text-align: center;
	/* display: flex; */
}

.ac_success_bottom_btn {
	border-radius: 10px;
	display: inline-block;
	opacity: 1;
	/* height: 42px; */
	padding-top: 15px;
	padding-bottom: 15px;
	width: 100%;
	/* 主要色/主要色 */
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
	font-family: PingFang SC;
	font-size: 18px;
	font-weight: normal;
	line-height: 16px;
	text-align: center;
	align-items: center;
	align-content: center;
	/* text-transform: uppercase; */
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	color: #ffffff;
}
</style>
<style scoped>
@media screen and (max-width: 900px) {
}
</style>

<style></style>
