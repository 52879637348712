<template>
	<div
		class="ac_detail_side_content_item flex align_items_c"
		@click="showSideDetail(side.id)"
	>
		<div class="ac_detail_side_content_item_pic">
			<img :src="side.pic" />
		</div>
		<div class="ac_detail_side_content_item_info">
			<div class="ac_detail_ticket_title">{{ side.title }}</div>
			<div class="ac_detail_ticket_date">
				<div class="ac_detail_ticket_info">
					<div class="ac_detail_ticket_info_item">
						<img
							src="https://res.metaera.hk/resources/assets/images/active/add_tu_w.svg"
						/>
						<span>{{ side.address }}</span>
					</div>
					<div class="ac_detail_ticket_info_item">
						<img
							src="https://res.metaera.hk/resources/assets/images/active/time_tu_w.svg"
						/>
						<span class="ac_detail_ticket_info_item_time">
							{{ formatDate(side.start_time) }} -
							{{ formatDate(side.end_time) }} {{ side.timeZone }}
						</span>
					</div>
					<div
						class="ac_detail_ticket_info_item"
						v-if="side.url != undefined && side.url.length > 0"
					>
						<img
							src="https://res.metaera.hk/resources/assets/images/active/url_tu_w.svg"
						/>
						<span
							class="ac_detail_ticket_info_item_url pointer"
							style="color: #0256ff"
							@click="showUrl(side.url)"
						>
							{{ side.url }}
						</span>
					</div>
					<!-- 设计稿上没有邀请码 -->
					<!-- <div
											class="ac_detail_ticket_info_item ac_detail_ticket_info_item1"
											v-if="
												side.specialCode != undefined &&
												side.specialCode.length > 0
											"
											@click="copyText(side.specialCode)"
										>
											<img
												src="https://res.metaera.hk/resources/assets/images/active/61.png"
											/>

											<div
												class="ac_detail_ticket_info_item_bg ac_detail_ticket_info_item_bg_copy"
											>
												<span>MetaEra专属邀请码：{{ side.specialCode }}</span>
												<img
													src="https://res.metaera.hk/resources/assets/images/active/62.png"
												/>
											</div>
										</div> -->
				</div>
				<div
					class="ac_calendar_aclist_item_people"
					v-if="side.sideevent == null || side.sideevent == ''"
				>
					<div class="ac_calendar_aclist_item_organizer">
						<span>Organizer</span>
						<img :src="getHostArray(side).pic" />
						<span>{{ getHostArray(side).name }}</span>
					</div>
				</div>
				<!-- v-else  -->
				<div class="ac_calendar_aclist_item_side" v-else>
					<div class="ac_calendar_aclist_item_side_left">
						# {{ side.sideevent_title }}</div
					>
					<div class="ac_calendar_aclist_item_side_right">SideEvent</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: ["side"],
	data() {
		return {
			name: "",
		};
	},
	methods: {
		formatDate(dateString) {
			const date = new Date(dateString);
			return date.toLocaleString("zh-CN", {
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
				hour: "2-digit",
				minute: "2-digit",
				hour12: false,
			});
		},
		getHostArray(item) {
			if (item.organizer && item.organizer.length > 10) {
				return JSON.parse(item.organizer)[0];
			} else {
				return { pic: "", name: "" };
			}
		},
		showSideDetail(id) {
			// 生成目标路由的 URL
			const routePath = this.$router.resolve({
				name: "/ac_detail",
				params: { id: id },
			}).href;
			// 在新标签页中打开目标路由
			window.open(routePath, "_blank");
		},
	},
};
</script>
<style scoped>
.ac_detail_ticket_title {
	font-size: clamp(14px, calc(100vw * 60 / 1440), 60px);
	font-weight: 900;
	line-height: clamp(20px, calc(100vw * 70 / 1440), 70px);
	/* 黑色主色（字段一级） */
	color: #fff;

	/* overflow: hidden; */
	/* display: -webkit-box; */
	/* -webkit-line-clamp: 2; */
	/* text-overflow: ellipsis; */
	/* -webkit-box-orient: vertical; */
}
.ac_detail_side_content_item_pic {
	flex-shrink: 0;
	margin-right: calc(100vw * 32 / 1440);
	width: calc(100vw * 560 / 1440);
	height: calc(100vw * 312 / 1440);
}
.ac_detail_ticket_info_item {
	margin-top: calc(100vw * 15 / 1440);
	display: flex;
	/* text-align: center; */
	align-content: center;
	align-items: center;
}
.ac_detail_ticket_info_item span {
	margin-left: clamp(5px, calc(100vw * 10 / 1440), 10px);
	color: #fff;
	font-size: clamp(10px, calc(100vw * 20 / 1440), 20px);
	line-height: 1;
	width: 95%;
	/* word-break: break-all; */
}
.ac_calendar_aclist_item_people,
.ac_calendar_aclist_item_side {
	margin-top: calc(100vw * 30 / 1440);
	display: inline-flex;
	justify-content: space-between;

	padding: calc(100vw * 6 / 1440) calc(100vw * 23 / 1440);
	border-radius: 20px;
	opacity: 1;
	box-sizing: border-box;
	background: rgba(216, 216, 216, 0.1);
}
.ac_calendar_aclist_item_people span,
.ac_calendar_aclist_item_side div {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: clamp(10px, calc(100vw * 14 / 1440), 14px);
	line-height: 1;
	/* 纯白 */
	color: #ffffff;
}
.ac_calendar_aclist_item_side_left {
	margin-right: 4px;
}
.ac_calendar_aclist_item_organizer img {
	margin-right: 4px;
}
.ac_calendar_aclist_item_people img {
	margin-left: calc(100vw * 10 / 1440);
	width: calc(100vw * 18 / 1440);
	height: calc(100vw * 18 / 1440);
	border-radius: 50%;
	object-fit: cover;
}
.ac_detail_side_content_item_pic img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 10px;
}
.ac_detail_side_content_item_info {
	width: calc(100vw * 600 / 1440);
}
.ac_detail_side_content_item .ac_detail_ticket_title {
	font-size: clamp(16px, calc(100vw * 30 / 1440), 30px);
	line-height: clamp(16px, calc(100vw * 50 / 1440), 50px);
}
.ac_detail_side_content_item .ac_detail_ticket_info_item span {
	margin-left: clamp(14px, calc(100vw * 18 / 1440), 18px);
	font-size: clamp(10px, calc(100vw * 14 / 1440), 14px);
}
</style>
